import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { SideBar, Header, Footer } from "../../custom";

const Health = ()=> {
    React.useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
    <>
        <Helmet>
        <title lang="en">RayJadore | Live a Healthier Lifestyle | Preventive measures  </title> 
            <meta name="description" content="Blogs topics cover ways to live a healthier lifestyle, foods to add to your diet, early detections and more specific information on common health conditions." /> 
            <meta property="og:title" content="A very important title"/>
            <meta name="keywords" content="health information, medical information, health news, health report, health newsletters, health newsletter, Health Publications, Perspectives on Prostate Disease, Cancer, ivf, airtificiaal insemination " />
             <link rel="canonical" href="https://rayjadore.com/health"></link>
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:title" content="RayJadore | Live a Healthier Lifestyle | Preventive measures " />
            <meta property="og:description" content="Blogs topics cover ways to live a healthier lifestyle, foods to add to your diet, early detections and more specific information on common health conditions." />
            <meta property="og:url" content="https://rayjadore.com/health" />
            <meta property="og:site_name" content="RayJadore" /> 
        </Helmet>
    <Header />
        <main id="main">
            <section className="section">
            <div class="container">
                <div class="row">

                <div class="col-md-9" data-aos="fade-up">
                    <h3 class="category-title">Category: Health</h3>

                    <div class="d-md-flex post-entry-2 half">
                        <Link to="/cholera-cases-rise-n-nigeria-as-rainy-season-begins" class="me-4 thumbnail">
                            <img src="assets/img/cholera.jpeg" alt="" class="img-fluid" />
                        </Link>
                        <div>
                            <div class="post-meta"><span class="date">Health</span> <span class="mx-1">&bullet;</span> <span>Jun 17th '24</span></div>
                            <h3><Link to="/cholera-cases-rise-n-nigeria-as-rainy-season-begins">Cholera Cases Rise in Nigeria as Rainy Season Begins</Link></h3>
                            <p className="lead">Nigeria's health officials are warning of a growing number of cholera cases across the 
                                country. Since the beginning of the year, there have been over 1,141 suspected cases and 65 confirmed cases with 30 deaths reported 
                                in 96 local governments across 30 states. This increase coincides with the start of the rainy season, which can create ideal 
                                conditions for the spread of cholera.</p>
                        </div>
                    </div>

                    <div class="d-md-flex post-entry-2 half">
                        <Link to="/optimize-cycle-pregnancy-performance" class="me-4 thumbnail">
                            <img src="assets/img/ovulation-mini.jpg" alt="" class="img-fluid" />
                        </Link>
                        <div>
                            <div class="post-meta"><span class="date">Health</span> <span class="mx-1">&bullet;</span> <span>May 18th '24</span></div>
                            <h3><Link to="/optimize-cycle-pregnancy-performance">Unlocking Your Fertile Power: Optimizing Your Cycle for Pregnancy or Peak Performance</Link></h3>
                            <p className="lead">Your menstrual cycle is more than just a monthly visitor. 
                                It's a powerful symphony of hormones that orchestrates everything from physical energy to mental focus.
                                But for many, this cycle feels like a mystery – a source of inconvenience or even frustration. 
                                What if you could unlock the secrets of your cycle and use them to your advantage?</p>
                        </div>
                    </div>

                    <div class="d-md-flex post-entry-2 half">
                    <Link to="/building-your-dream-family-with-ivf" class="me-4 thumbnail">
                        <img src="assets/img/ivf.jpeg" alt="" class="img-fluid" />
                    </Link>
                    <div>
                        <div class="post-meta"><span class="date">Health</span> <span class="mx-1">&bullet;</span> <span>May 19th '24</span></div>
                        <h3><Link to="/building-your-dream-family-with-ivf">Building Your Dream Family: Exploring the Possibilities of IVF</Link></h3>
                        <p className="lead">For many couples, the dream of starting a family can feel frustratingly out of reach due to infertility.
                            But modern medicine offers a beacon of hope: In Vitro Fertilization, or IVF.
                             This advanced medical procedure has helped millions of couples achieve parenthood, 
                             making it a powerful tool for building families.
                            </p>
                    </div>
                    </div>

                    <div class="d-md-flex post-entry-2 half">
                    <Link to="#" class="me-4 thumbnail">
                        <img src="assets/img/imaging.jpg" alt="" class="img-fluid" />
                    </Link>
                    <div>
                        <div class="post-meta"><span class="date">Health</span> <span class="mx-1">&bullet;</span> <span>May 19th '24</span></div>
                        <h3><Link to="#">Cracking the Cancer Code: How Medical Imaging Aids in Early Detection and Treatment</Link></h3>
                        <p className="lead">Cancer. The very word sends shivers down spines. But what if we told you there's a revolution happening in the fight against this disease?
                             A revolution with a powerful weapon in our arsenal: medical imaging.
                            </p>
                    </div>
                    </div>

                    <div class="d-md-flex post-entry-2 half">
                    <Link to="#" class="me-4 thumbnail">
                        <img src="assets/img/pregnancy.jpg" alt="" class="img-fluid" />
                    </Link>
                    <div>
                        <div class="post-meta"><span class="date">Health</span> <span class="mx-1">&bullet;</span> <span>May 14th '24</span></div>
                        <h3><Link to="#">Life Insurance And Pregnancy: A Working Mom’s Guide</Link></h3>
                        <p className="lead">Congratulations! You're expecting a little one, a joyous time filled with excitement and preparation. 
                            As a working mom, you likely understand the importance of financial security for your family. 
                            Life insurance can be a crucial part of that security, and pregnancy is a great time to consider your options.
                              </p>
                    </div>
                    </div>

                    <div class="d-md-flex post-entry-2 half">
                    <Link to="#" class="me-4 thumbnail">
                        <img src="assets/img/postrate.jpg" alt="" class="img-fluid" />
                    </Link>
                    <div>
                        <div class="post-meta"><span class="date">Health</span> <span class="mx-1">&bullet;</span> <span>May 18th '24</span></div>
                        <h3><Link to="#">Fight Prostate Cancer Like a Champion: Your Guide to Early Detection and Treatment Options</Link></h3>
                        <p className="lead">Prostate cancer. The name alone can send shivers down a man's spine. But here's the truth: with knowledge and proactive steps,
                             you can become a champion in your own health journey. This guide equips you with the power of early detection and treatment options,
                              putting you in control of your well-being.
                        </p>
                    </div>
                    </div>

                    {/* <div class="text-start py-4">
                        <div class="custom-pagination">
                            <Link to="#" class="prev">Prevous</Link>
                            <Link to="#" class="active">1</Link>
                            <Link to="#">2</Link>
                            <Link to="#">3</Link>
                            <Link to="#">4</Link>
                            <Link to="#">5</Link>
                            <Link to="#" class="next">Next</Link>
                        </div>
                    </div> */}
                </div>

                <SideBar />

                </div>
            </div>
            </section>
    </main>
    <Footer />
  </>
    )
};

export default Health;