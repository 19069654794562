import React from 'react';
import { Link } from "react-router-dom";

export const Header = () => (
    <header id="header" className="header d-flex align-items-center fixed-top">
    <div className="container-fluid container-xl d-flex align-items-center justify-content-between">

      <Link to="/" className="logo d-flex align-items-center">
        <img src="assets/img/logo.png" alt="RayJadore" />
        <h1>RayJadore</h1>
      </Link>

      <nav id="navbar" className="navbar">
        <ul>
        <li><Link to="/">Home</Link></li>
          <li><Link to="/business">Business</Link></li>
          <li><Link to="/health">Health</Link></li>
          <li><Link to="/technology">Technology</Link></li>
          {/* <li className="dropdown"><Link to="category.html"><span>Categories</span> <i className="bi bi-chevron-down dropdown-indicator"></i></Link>
            <ul>
              <li><Link to="search-result.html">Search Result</Link></li>
              <li><Link to="#">Drop Down 1</Link></li>
              <li className="dropdown"><Link to="#"><span>Deep Drop Down</span> <i className="bi bi-chevron-down dropdown-indicator"></i></Link>
                <ul>
                  <li><Link to="#">Deep Drop Down 1</Link></li>
                  <li><Link to="#">Deep Drop Down 2</Link></li>
                  <li><Link to="#">Deep Drop Down 3</Link></li>
                  <li><Link to="#">Deep Drop Down 4</Link></li>
                  <li><Link to="#">Deep Drop Down 5</Link></li>
                </ul>
              </li>
              <li><Link to="#">Drop Down 2</Link></li>
              <li><Link to="#">Drop Down 3</Link></li>
              <li><Link to="#">Drop Down 4</Link></li>
            </ul>
          </li> */}

          <li><Link to="#">About</Link></li>
          <li><Link to="/contact">Contact</Link></li>
        </ul>
      </nav>

      <div className="position-relative">
        <Link to="#" className="mx-2"><span className="bi-facebook"></span></Link>
        <Link to="#" className="mx-2"><span className="bi-twitter"></span></Link>
        <Link to="#" className="mx-2"><span className="bi-instagram"></span></Link>
      </div>

    </div>

  </header>
);
