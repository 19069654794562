import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { SideBar, Header, Footer } from "../../custom";

const Business = ()=> {

    React.useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
    <>
        <Helmet>
            <title lang="en">RayJadore | Best business blogs and articles for business owners, entrepreneurs & freelancers </title> 
            <meta name="description" content=" Best Business Blog for articles on business topics such as marketing, education, technology, innovation and more." /> 
            <meta property="og:title" content="RayJadore | Best business blogs and articles for business owners, entrepreneurs & freelancers" />
            <meta name="keywords" content="becoming successful in business, healthy living, high paying tech jobs, tech jobs that are in trend, relationship tips,
                beauty tips, living a fulfilling life " />
             <link rel="canonical" href="https://rayjadore.com/business"></link>
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:description" content="Best Business Blog for articles on business topics such as marketing, education, technology, innovation and more." />
            <meta property="og:url" content="https://rayjadore.com/business" />
            <meta property="og:site_name" content="RayJadore" /> 
        </Helmet>
    <Header />
        <main id="main">
            <section className="section">
            <div class="container">
                <div class="row">

                <div class="col-md-9" data-aos="fade-up">
                    <h3 class="category-title">Category: Business</h3>

                    <div class="d-md-flex post-entry-2 half">
                    <Link to="/female-founder-startup-grants" class="me-4 thumbnail">
                        <img src="assets/img/grant.jpg" alt="" class="img-fluid" />
                    </Link>
                        <div>
                            <div class="post-meta"><span class="date">Business</span> <span class="mx-1">& Tech;</span> <span>May 23th '24</span></div>
                            <h3><Link to="/female-founder-startup-grants">Receive free Money: Top Grants to Fuel Your Female-Founded Startup</Link></h3>
                            <p className="lead"><strong>Thinking of starting an online store but worried about upfront costs?</strong> Funding a business can 
                            be a hurdle for any entrepreneur and as a female entrepreneur, securing funding can be one of the biggest challenges in starting 
                            and growing your business. </p>
                            <div class="d-flex align-items-center author">
                            <div class="photo"><img src="assets/img/person-2.jpg" alt="" class="img-fluid" /></div>
                            </div>
                        </div>
                    </div>

                     <div class="d-md-flex post-entry-2 half">
                    <Link to="/how-to-start-your-lucrative-import-export-business" class="me-4 thumbnail">
                        <img src="assets/img/export.jpg" alt="" class="img-fluid" />
                    </Link>
                    <div> 
                        <div class="post-meta"><span class="date">Business</span> <span class="mx-1">&bullet;</span> <span>May 18th '24</span></div>
                        <h3><Link to="/how-to-start-your-lucrative-import-export-business">How to start your own lucrative export Business</Link></h3>
                        <p className="lead"><b>Have you ever dreamt of building a business that impacts a nation's economic pulse? </b>
                            The import/export industry offers that thrill and much more! This exciting field isn't just about big picture economics.
                            It's about being your own boss, forging international connections, and profiting from in-demand goods. 
                            </p>
                        <div class="d-flex align-items-center author">
                        <div class="photo"><img src="assets/img/person-2.jpg" alt="" class="img-fluid" /></div>
                        </div>
                    </div>
                    </div>

                    <div class="d-md-flex post-entry-2 half">
                    <Link to="/how-to-start-your-drop-shipping-business" class="me-4 thumbnail">
                        <img src="assets/img/dropship.jpg" alt="" class="img-fluid" />
                    </Link>
                        <div>
                            <div class="post-meta"><span class="date">Business</span> <span class="mx-1">& Tech;</span> <span>May 13th '24</span></div>
                            <h3><Link to="/how-to-start-your-drop-shipping-business">Be Your Own Boss: How to Launch Your Own Drop Shipping Business</Link></h3>
                            <p className="lead"><strong>Thinking of starting an online store but worried about upfront costs?</strong> Dropshipping might be the answer!
                            This business model lets you sell a wide variety of products without actually owning them. 
                            You don't need to invest heavily in inventory, making it easier to start with a limited budget. 
                            All you need to do is Focus on marketing and customer service, while the supplier handles fulfillment</p>
                            <div class="d-flex align-items-center author">
                            <div class="photo"><img src="assets/img/person-2.jpg" alt="" class="img-fluid" /></div>
                            </div>
                        </div>
                    </div>

                    <div class="d-md-flex post-entry-2 half">
                    <Link to="/online-arbitrage-guide/amazon-seller-tips" class="me-4 thumbnail">
                        <img src="assets/img/post-landscape-6.jpg" alt="" class="img-fluid" />
                    </Link>
                    <div>
                        <div class="post-meta"><span class="date">Business</span> <span class="mx-1">& Tech;</span> <span>May 16th '24</span></div>
                        <h3><Link to="/online-arbitrage-guide/amazon-seller-tips">Online Arbitrage 101: A Beginner's Guide to Selling on Amazon</Link></h3>
                        <p className="lead"> Feeling worn out by the daily 9-to-5 routine? Want to be your own boss and choose your own work hours? 
                            Online arbitrage could be the answer! It's like a digital treasure hunt where, instead of finding gold coins, 
                            you discover amazing deals that can boost your bank account.</p>
                        <div class="d-flex align-items-center author">
                        <div class="photo"><img src="assets/img/person-2.jpg" alt="" class="img-fluid" /></div>
                        </div>
                    </div>
                    </div>

                    <div class="d-md-flex post-entry-2 half">
                    <Link to="/guide-to-fund-your-business-through-crowdfunding" class="me-4 thumbnail">
                        <img src="assets/img/crowdfunding.jpg" alt="" class="img-fluid" />
                    </Link>
                    <div>
                        <div class="post-meta"><span class="date">Business</span> <span class="mx-1">& Tech;</span> <span>May 19th '24</span></div>
                        <h3><Link to="/guide-to-fund-your-business-through-crowdfunding">From Idea to Empire: The Ultimate Guide to funding your business through crowdfunding </Link></h3>
                        <p className="lead"> Tired of the 9-to-5 grind?  Does your head buzz with a revolutionary idea that could change the world
                             (or at least make your life a whole lot easier)?  Crowdfunding can be your rocket launcher to success! 
                              This guide is your blueprint to crafting a killer campaign that'll have investors lining up to blast your business off the ground. 
                            </p>
                    </div>
                    </div>

                    <div class="d-md-flex post-entry-2 half">
                        <Link to="/female-founder-startup-grants" class="me-4 thumbnail">
                            <img src="assets/img/grants.jpg" alt="" class="img-fluid" />
                        </Link>
                        <div>
                            <div class="post-meta"><span class="date">Business</span> <span class="mx-1">&bullet;</span> <span>May 18th '24</span></div>
                            <h3><Link to="/female-founder-startup-grants">Receive free Money: Top Grants to Fuel Your Female-Founded Startup</Link></h3>
                            <p className="lead">Building your dream business is empowering, but funding it can feel like climbing Mount Everest in flip flops.
                                Fear not, fearless founder! This guide unveils top grants specifically designed to supercharge female-led ventures. 
                                Get ready to ditch the bootstrapping struggle and watch your business idea take flight with the power of free capital!</p>
                        </div>
                    </div>
                    <div class="d-md-flex post-entry-2 half">
                        <Link to="/boost-your-business-visibility-seo-hacks-to-become-the-go-to-business" class="me-4 thumbnail">
                            <img src="assets/img/seo.jpg" alt="" class="img-fluid" />
                        </Link>
                        <div>
                            <div class="post-meta"><span class="date">Tech</span> <span class="mx-1">& Business;</span> <span>May 17th '24</span></div>
                            <h3><Link to="/boost-your-business-visibility-seo-hacks-to-become-the-go-to-business">Boost your Business visibility: SEO Hacks to Become the Go-To Business</Link></h3>
                            <p className="lead">Ever feel like your business is buried in the online jungle? You create amazing products, 
                                provide stellar service, but customers just can't seem to find you? Don't worry, it's not you, it's  Search Engine Optimization (SEO).
                                SEO is the secret sauce that helps search engines like Google recognize your awesomeness 
                                and present you to the right people at the right time
                            </p>
                        </div>
                    </div>

                    {/* <div class="text-start py-4">
                    <div class="custom-pagination">
                        <Link to="#" class="prev">Prevous</Link>
                        <Link to="#" class="active">1</Link>
                        <Link to="#">2</Link>
                        <Link to="#">3</Link>
                        <Link to="#">4</Link>
                        <Link to="#">5</Link>
                        <Link to="#" class="next">Next</Link>
                    </div>
                    </div> */}
                </div>

                <SideBar />

                </div>
            </div>
            </section>
    </main>
    <Footer />
  </>
    )
};

export default Business;