import React from "react";
import { Helmet } from 'react-helmet';
import { Hero, Header, Footer, Posts, Tech, Business, Lifestyle  } from "../../custom";


const Home = () => {

    React.useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }, []);
    
    return (
        <>
            <Helmet>
                <title lang="en">Welcome to RayJadore| Thrive in Business, Health and Technology </title> 
                <meta name="description" content="Feeling stuck? Our blog unlocks your potential! Get actionable tips on Business,
                  Health, Tech, Relationships, Beauty, Travel & Well-being. Thrive at work, live healthier, & boost your happiness." /> 
                 <meta property="og:title" content="Welcome to RayJadore| Thrive in Business, Health and Technology" />
                <meta name="keywords" content="becoming successful in business, healthy living, high paying tech jobs, tech jobs that are in trend, relationship tips,
                  beauty tips, living a fulfilling life " />
                <link rel="canonical" href="https://rayjadore.com"></link>
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_US" />
               
                <script async="async" data-cfasync="false" src="//pl23438730.highcpmgate.com/0fbcd8bcad6b46625d3792def33f8642/invoke.js"></script>
                <meta property="og:description" content="Feeling stuck? Our blog unlocks your potential! Get actionable tips on Business,
                  Health, Tech, Relationships, Beauty, Travel & Well-being. Thrive at work, live healthier, & boost your happiness." />
                <meta property="og:url" content="https://rayjadore.com" />
                <meta property="og:site_name" content="RayJadore" /> 

            </Helmet>
            <Header />
            <main id="main" >
                {/* <Hero /> */}
                <Posts />
                <Tech />
                {/* <Business /> */}
                {/* <Lifestyle /> */}
            </main>
            <Footer />
        </>
    )
}

export default Home;