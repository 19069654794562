import React from "react";
import { Link } from "react-router-dom";
// import { AdsUnit } from "./AdsUnit";

export const Posts = () => {

    return (
        <section id="posts" className="posts section">
        <div className="container" data-aos="fade-up">
          <div className="row g-5">
            <div className="col-lg-4">
              <div className="post-entry-1 lg">
                <Link to="/how-to-start-your-lucrative-import-export-business"><img src="assets/img/export.jpg" alt="start your own lucrative import/export Business" className="img-fluid" /></Link>
                <div className="post-meta"><span className="date">Business</span> <span className="mx-1"></span> <span>Jun 25th '24</span></div>
                <h2><Link to="/how-to-start-your-lucrative-import-export-business">How to start your own lucrative export Business </Link></h2>
                <p className="mb-4 d-block lead">
                Have you ever dreamt of building a business that impacts a nation's economic pulse? <br/>
                The export industry offers that thrill and much more!
                This exciting field isn't just about big picture economics.<br/>
                 It's about being your own boss, forging international connections,
                  and profiting from in-demand goods.  
                  Are you ready to be a part of the that revolution?
                    </p>

                    <amp-ad
                        layout="fixed"
                        width="300"
                        height="250"
                        type="adsense"
                        data-ad-client="ca-pub-8866274742884048"
                        data-ad-slot="5923097863">
                    </amp-ad>

              </div>
  
            </div>
  
            <div className="col-lg-8">
              <div className="row g-5">
                <div className="col-lg-4 border-start custom-border">
                  <div className="post-entry-1">
                    <Link to="/how-to-start-your-drop-shipping-business"><img src="assets/img/dropship.jpg" alt="Launch Your Own Drop Shipping Business" className="img-fluid" /></Link>
                    <div className="post-meta"><span className="date">Business</span> <span className="mx-1"></span> <span>May 22nd '24</span></div>
                    <h2><Link to="/how-to-start-your-drop-shipping-business">Be Your Own Boss: How to Start Your Drop Shipping Business</Link></h2>
                  </div>
                  <div className="post-entry-1">
                    <Link to="/building-your-dream-family-with-ivf"><img src="assets/img/ivf.jpeg" alt=" Exploring the Possibilities of IVF" className="img-fluid" /></Link>
                    <div className="post-meta"><span className="date">Health</span> <span className="mx-1"></span> <span>May 17th '24</span></div>
                    <h2><Link to="/building-your-dream-family-with-ivf">Building Your Dream Family: Exploring the Possibilities of IVF</Link></h2>
                  </div>
                  {/* <div className="post-entry-1">
                    <AdsUnit adSlot={'9420056721'}  adLayoutKey={"-7a+f2-19-5c+ec"} />
    
                  </div> */}
                  <div className="post-entry-1">
                    <Link to="#"><img src="assets/img/theft.jpeg" alt="How to Prevent Identity Theft" className="img-fluid" /></Link>
                    <div className="post-meta"><span className="date">Tech</span> <span className="mx-1"></span> <span>May 15th '24</span></div>
                    <h2><Link to="#">Shielding Yourself: How to Prevent Identity Theft</Link></h2>
                  </div>

                
                 
                </div>

                <div className="col-lg-4 border-start custom-border">
                  <div className="post-entry-1">
                    <Link to="/optimize-cycle-pregnancy-performance"><img src="assets/img/ovulation-mini.jpg" alt="Unlocking Your Fertile Power" className="img-fluid" /></Link>
                    <div className="post-meta"><span className="date">Health</span> <span className="mx-1"></span> <span>May 28th '24</span></div>
                    <h2><Link to="/optimize-cycle-pregnancy-performance">Unlocking Your Fertile Power: Optimizing Your Cycle for Pregnancy or Peak Performance</Link></h2>
                  </div>
                  <div className="post-entry-1">
                    <Link to="/online-arbitrage-guide/amazon-seller-tips"><img src="assets/img/post-landscape-6.jpg" alt="Lucrative World of Online Arbitrage" className="img-fluid" /></Link>
                    <div className="post-meta"><span className="date">Technology</span> <span className="mx-1"></span> <span>Apr 1st '24</span></div>
                    <h2><Link to="/online-arbitrage-guide/amazon-seller-tips">Online Arbitrage 101: A Beginner's Guide to Selling on Amazon</Link></h2>
                  </div>
                  <div className="post-entry-1">
                    <Link to="/female-founder-startup-grants"><img src="assets/img/post-landscape-8.jpg" alt="How to Build a Thriving Startup as a Woman" className="img-fluid" /></Link>
                    <div className="post-meta"><span className="date">Tech</span> <span className="mx-1">& Business</span> <span>May 23th '24</span></div>
                    <h2><Link to="/female-founder-startup-grants">Receive free Money: Top Grants to Fuel Your Female-Founded Startup</Link></h2>
                  </div>
                </div>
  
                <div className="col-lg-4">
                  <div className="trending">
                    <h3>Trending</h3>
                    <ul className="trending-post">
                      <li>
                        <Link to="/boost-your-business-visibility-seo-hacks-to-become-the-go-to-business">
                          <h3>Boost your Business visibility: SEO Hacks to Become the Go-To Business</h3>
                          {/* <span className="author">Jane Cooper</span> */}
                        </Link>
                      </li>
                      <li>
                        <Link to="/cholera-cases-rise-n-nigeria-as-rainy-season-begins">
                          <h3>Cholera Cases Rise in Nigeria as Rainy Season Begins </h3>
                          {/* <span className="author">Wade Warren</span> */}
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <h3>From Overwhelmed to Empowered:  Navigating the Financial and Practical Realities of Caring for Loved Ones</h3>
                          {/* <span className="author">Esther Howard</span> */}
                        </Link>
                      </li>
                      <li>
                        <Link to="/career-advice/level-up-your-career-hacking-your-way-to-dream-tech-job">
                          <h3>Level Up Your Career: Hacking Your Way to Your Dream Tech Job</h3>
                          {/* <span className="author">Cameron Williamson</span> */}
                        </Link>
                      </li>
                      <li>
                        <Link to="/guide-to-fund-your-business-through-crowdfunding">
                          <h3>From Idea to Empire: The Ultimate Guide to funding your business through crowdfunding</h3>
                          {/* <span className="author">Jenny Wilson</span> */}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
  
          </div>
        </div>
      </section>
    )
};
