import React from "react";
import { Helmet } from "react-helmet";
import { Header, Footer } from '../../custom'

const Contact = () => {
    return (
            <>
              <Helmet>
            <title lang="en">RayJadore | Contact us  </title> 
            <meta name="description" content=" Best Business Blog for articles on business topics such as marketing, education, technology, innovation and more." /> 
            <meta property="og:title" content="RayJadore | Contact us " />
            <meta name="keywords" content="becoming successful in business, healthy living, high paying tech jobs, tech jobs that are in trend, relationship tips,
                beauty tips, living a fulfilling life " />
             <link rel="canonical" href="https://rayjadore.com/contact"></link>
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:description" content="Best Business Blog for articles on business topics such as marketing, education, technology, innovation and more." />
            <meta property="og:url" content="https://rayjadore.com/business" />
            <meta property="og:site_name" content="RayJadore" /> 
        </Helmet>
            <Header />
            <main id="main">
                <section id="contact" className="contact mb-5 section">
                <div className="container" data-aos="fade-up">

                    <div className="row">
                    <div className="col-lg-12 text-center mb-5">
                        <h1 className="page-title">Contact us</h1>
                    </div>
                    </div>

                    <div className="row gy-4">

                    <div className="col-md-4">
                        <div className="info-item">
                        <i className="bi bi-geo-alt"></i>
                        <h3>Address</h3>
                        <address>No 1 Sodipo Close Olusosun Ikeja, Lagos</address>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="info-item info-item-borders">
                        <i className="bi bi-phone"></i>
                        <h3>Phone Number</h3>
                        <p><a href="tel:+2348066379681">+234-806-637-9681</a></p>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="info-item">
                        <i className="bi bi-envelope"></i>
                        <h3>Email</h3>
                        <p><a href="mailto:dike4mii4real@gmail.com">dike4mii4real@gmail.com</a></p>
                        </div>
                    </div>

                    </div>

                    <div className="form mt-5">
                    <form role="form" className="php-email-form">
                        <div className="row">
                        <div className="form-group col-md-6">
                            <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                        </div>
                        <div className="form-group col-md-6">
                            <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                        </div>
                        </div>
                        <div className="form-group">
                        <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
                        </div>
                        <div className="form-group">
                        <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
                        </div>
                        <div className="my-3">
                        <div className="loading">Loading</div>
                        <div className="error-message"></div>
                        <div className="sent-message">Your message has been sent. Thank you!</div>
                        </div>
                        <div className="text-center"><button type="submit">Send Message</button></div>
                    </form>
                    </div>

                </div>
                </section>
            </main>
            <Footer />
            </>
    )
}

export default Contact;