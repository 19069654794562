import React from "react";
import { Link } from 'react-router-dom';

export const SideBar = () => (
    <div class="col-md-3">
        <div class="aside-block">

        <ul class="nav nav-pills custom-tab-nav mb-4" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
            <button class="nav-link active" id="pills-popular-tab" data-bs-toggle="pill" data-bs-target="#pills-popular" type="button" role="tab" aria-controls="pills-popular" aria-selected="true">Popular</button>
            </li>
            <li class="nav-item" role="presentation">
            <button class="nav-link" id="pills-trending-tab" data-bs-toggle="pill" data-bs-target="#pills-trending" type="button" role="tab" aria-controls="pills-trending" aria-selected="false">Trending</button>
            </li>
            <li class="nav-item" role="presentation">
            <button class="nav-link" id="pills-latest-tab" data-bs-toggle="pill" data-bs-target="#pills-latest" type="button" role="tab" aria-controls="pills-latest" aria-selected="false">Latest</button>
            </li>
        </ul>

        <div class="tab-content" id="pills-tabContent">

            <div class="tab-pane fade show active" id="pills-popular" role="tabpanel" aria-labelledby="pills-popular-tab">
            <div class="post-entry-1 border-bottom">
                <div class="post-meta"><span class="date">Sport</span> <span class="mx-1">&bullet;</span> <span>Jul 5th '22</span></div>
                <h2 class="mb-2"><Link to="#">How to Avoid Distraction and Stay Focused During Video Calls?</Link></h2>
            </div>

            <div class="post-entry-1 border-bottom">
                <div class="post-meta"><span class="date">Lifestyle</span> <span class="mx-1">&bullet;</span> <span>Jul 5th '22</span></div>
                <h2 class="mb-2"><Link to="#">17 Pictures of Medium Length Hair in Layers That Will Inspire Your New Haircut</Link></h2>
            </div>

            <div class="post-entry-1 border-bottom">
                <div class="post-meta"><span class="date">Culture</span> <span class="mx-1">&bullet;</span> <span>Jul 5th '22</span></div>
                <h2 class="mb-2"><Link to="#">9 Half-up/half-down Hairstyles for Long and Medium Hair</Link></h2>
            </div>

            <div class="post-entry-1 border-bottom">
                <div class="post-meta"><span class="date">Lifestyle</span> <span class="mx-1">&bullet;</span> <span>Jul 5th '22</span></div>
                <h2 class="mb-2"><Link to="#">Life Insurance And Pregnancy: A Working Mom’s Guide</Link></h2>
            </div>

            <div class="post-entry-1 border-bottom">
                <div class="post-meta"><span class="date">Business</span> <span class="mx-1">&bullet;</span> <span>Jul 5th '22</span></div>
                <h2 class="mb-2"><Link to="#">The Best Homemade Masks for Face (keep the Pimples Away)</Link></h2>
            </div>

            <div class="post-entry-1 border-bottom">
                <div class="post-meta"><span class="date">Lifestyle</span> <span class="mx-1">&bullet;</span> <span>Jul 5th '22</span></div>
                <h2 class="mb-2"><Link to="#">10 Life-Changing Hacks Every Working Mom Should Know</Link></h2>
            </div>
            </div> 

            <div class="tab-pane fade" id="pills-trending" role="tabpanel" aria-labelledby="pills-trending-tab">
            <div class="post-entry-1 border-bottom">
                <div class="post-meta"><span class="date">Lifestyle</span> <span class="mx-1">&bullet;</span> <span>Jul 5th '22</span></div>
                <h2 class="mb-2"><Link to="#">17 Pictures of Medium Length Hair in Layers That Will Inspire Your New Haircut</Link></h2>
            </div>

            <div class="post-entry-1 border-bottom">
                <div class="post-meta"><span class="date">Culture</span> <span class="mx-1">&bullet;</span> <span>Jul 5th '22</span></div>
                <h2 class="mb-2"><Link to="#">9 Half-up/half-down Hairstyles for Long and Medium Hair</Link></h2>
            </div>

            <div class="post-entry-1 border-bottom">
                <div class="post-meta"><span class="date">Lifestyle</span> <span class="mx-1">&bullet;</span> <span>Jul 5th '22</span></div>
                <h2 class="mb-2"><Link to="#">Life Insurance And Pregnancy: A Working Mom’s Guide</Link></h2>
            </div>

            <div class="post-entry-1 border-bottom">
                <div class="post-meta"><span class="date">Sport</span> <span class="mx-1">&bullet;</span> <span>Jul 5th '22</span></div>
                <h2 class="mb-2"><Link to="#">How to Avoid Distraction and Stay Focused During Video Calls?</Link></h2>
            </div>
            <div class="post-entry-1 border-bottom">
                <div class="post-meta"><span class="date">Business</span> <span class="mx-1">&bullet;</span> <span>Jul 5th '22</span></div>
                <h2 class="mb-2"><Link to="#">The Best Homemade Masks for Face (keep the Pimples Away)</Link></h2>
            </div>

            <div class="post-entry-1 border-bottom">
                <div class="post-meta"><span class="date">Lifestyle</span> <span class="mx-1">&bullet;</span> <span>Jul 5th '22</span></div>
                <h2 class="mb-2"><Link to="#">10 Life-Changing Hacks Every Working Mom Should Know</Link></h2>
            </div>
            </div>

            <div class="tab-pane fade" id="pills-latest" role="tabpanel" aria-labelledby="pills-latest-tab">
            <div class="post-entry-1 border-bottom">
                <div class="post-meta"><span class="date">Lifestyle</span> <span class="mx-1">&bullet;</span> <span>Jul 5th '22</span></div>
                <h2 class="mb-2"><Link to="#">Life Insurance And Pregnancy: A Working Mom’s Guide</Link></h2>
            </div>

            <div class="post-entry-1 border-bottom">
                <div class="post-meta"><span class="date">Business</span> <span class="mx-1">&bullet;</span> <span>Jul 5th '22</span></div>
                <h2 class="mb-2"><Link to="#">The Best Homemade Masks for Face (keep the Pimples Away)</Link></h2>
            </div>

            <div class="post-entry-1 border-bottom">
                <div class="post-meta"><span class="date">Lifestyle</span> <span class="mx-1">&bullet;</span> <span>Jul 5th '22</span></div>
                <h2 class="mb-2"><Link to="#">10 Life-Changing Hacks Every Working Mom Should Know</Link></h2>
            </div>

            <div class="post-entry-1 border-bottom">
                <div class="post-meta"><span class="date">Sport</span> <span class="mx-1">&bullet;</span> <span>Jul 5th '22</span></div>
                <h2 class="mb-2"><Link to="#">How to Avoid Distraction and Stay Focused During Video Calls?</Link></h2>
            </div>

            <div class="post-entry-1 border-bottom">
                <div class="post-meta"><span class="date">Lifestyle</span> <span class="mx-1">&bullet;</span> <span>Jul 5th '22</span></div>
                <h2 class="mb-2"><Link to="#">17 Pictures of Medium Length Hair in Layers That Will Inspire Your New Haircut</Link></h2>
            </div>

            <div class="post-entry-1 border-bottom">
                <div class="post-meta"><span class="date">Culture</span> <span class="mx-1">&bullet;</span> <span>Jul 5th '22</span></div>
                <h2 class="mb-2"><Link to="#">9 Half-up/half-down Hairstyles for Long and Medium Hair</Link></h2>
            </div>

            </div> 

        </div>
        </div>



        <div class="aside-block">
        <h3 class="aside-title">Categories</h3>
        <ul class="aside-links list-unstyled">
            <li><Link to=""><i class="bi bi-chevron-right"></i> Business</Link></li>
            <li><Link to=""><i class="bi bi-chevron-right"></i> Technology</Link></li>
            <li><Link to=""><i class="bi bi-chevron-right"></i> Health</Link></li>
        </ul>
        </div>

        <div class="aside-block">
        <h3 class="aside-title">Tags</h3>
        <ul class="aside-tags list-unstyled">
            <li><Link to="">Business</Link></li>
            <li><Link to="">Technology</Link></li>
            <li><Link to="">Health</Link></li>
            <li><Link to="">Women in Tech</Link></li>
            <li><Link to="">Grants</Link></li>
            <li><Link to="">Remote Jobs</Link></li>
            <li><Link to="">SEO</Link></li>
        </ul>
        </div>

  </div>
);