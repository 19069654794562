import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { SideBar, Header, Footer } from "../../custom";

const Technology = ()=> {

    React.useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
    <>
        <Helmet>
            <title lang="en">RayJadore | Startups and Technology, Founders, Grants, Guides, Reviews </title> 
            <meta name="description" content=" reviews and expert advice so you can know more about tech, work smarter, and grow your business, securely" /> 
            <meta property="og:title" content="A very important title"/>
            <meta name="keywords" content="becoming successful in business, healthy living, high paying tech jobs, tech jobs that are in trend, relationship tips,
                beauty tips, living a fulfilling life " />
             <link rel="canonical" href="https://rayjadore.com/technology"></link>
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:title" content="RayJadore | Startups and Technology, Founders, Grants, Guides, Reviews" />
            <meta property="og:description" content="reviews and expert advice so you can know more about tech, work smarter, and grow your business, securely" />
            <meta property="og:url" content="https://rayjadore.com/technology" />
            <meta property="og:site_name" content="RayJadore" /> 
        </Helmet>
    <Header />
        <main id="main">
            <section className="section">
            <div class="container">
                <div class="row">

                <div class="col-md-9" data-aos="fade-up">
                    <h3 class="category-title">Category: Technology</h3>

                    <div class="d-md-flex post-entry-2 half">
                        <Link to="/keyword-research-for-on-page-seo-the-secret-weapon-of-business-growth" class="me-4 thumbnail">
                            <img src="assets/img/keyword/keyword.jpg" alt="" class="img-fluid" />
                        </Link>
                        <div>
                            <div class="post-meta"><span class="date">Tech</span> <span class="mx-1">&bullet;</span> <span>Jun 27th '24</span></div>
                            <h3><Link to="/keyword-research-for-on-page-seo-the-secret-weapon-of-business-growth">Keyword Research for On-Page SEO: The Secret Weapon of Business Growth</Link></h3>
                            <p className="lead">You've put your heart and soul into creating an amazing online presence, but you need to get the customer traffic you hoped for. I get it - I've been there too.
                            I spent so much time trying to get traffic to most of the businesses i had online for over half a decade. But then I discovered the magic of SEO and
                             keyword research, and it completely turned things around for me.
                                </p>
                        </div>
                    </div>

                    <div class="d-md-flex post-entry-2 half">
                        <Link to="/level-up-your-career-hacking-your-way-to-dream-tech-job" class="me-4 thumbnail">
                            <img src="assets/img/hacking.jpg" alt="" class="img-fluid" />
                        </Link>
                        <div>
                            <div class="post-meta"><span class="date">Tech</span> <span class="mx-1">&bullet;</span> <span>May 19th '24</span></div>
                            <h3><Link to="/level-up-your-career-hacking-your-way-to-dream-tech-job">FLevel Up Your Career: Hacking Your Way to Your Dream Tech Job</Link></h3>
                            <p className="lead">From mind-blowing gadgets to world-changing apps, tech is everywhere, and it's not going anywhere. Incase you are 
                                feeling a little stuck in your current career path, the daily grind just isn't sparking joy, and that paycheck isn't quite fueling
                                 your dreams. But hey, there's good news!
                                </p>
                        </div>
                    </div>

                    <div class="d-md-flex post-entry-2 half">
                        <Link to="#" class="me-4 thumbnail">
                            <img src="assets/img/post-landscape-8.jpg" alt="" class="img-fluid" />
                        </Link>
                        <div>
                            <div class="post-meta"><span class="date">Tech</span> <span class="mx-1">&bullet;</span> <span>May 19th '24</span></div>
                            <h3><Link to="#">From Idea to Empire: How to Build a Thriving Startup as a Woman</Link></h3>
                            <p className="lead">The startup world is brimming with potential, but for female founders, the journey can come with unique challenges. 
                                From navigating funding biases to building a strong support network, 
                                women entrepreneurs need to be resourceful and determined. 
                                </p>
                        </div>
                    </div>

                    <div class="d-md-flex post-entry-2 half">
                    <Link to="#" class="me-4 thumbnail">
                        <img src="assets/img/theft.jpeg" alt="" class="img-fluid" />
                    </Link>
                    <div>
                        <div class="post-meta"><span class="date">Tech</span> <span class="mx-1">&bullet;</span> <span>May 17th '24</span></div>
                        <h3><Link to="#">Shielding Yourself: How to Prevent Identity Theft</Link></h3>
                        <p className="lead">Identity theft is a serious crime where someone steals your personal information to impersonate you.
                             They can use this information to open new accounts, rack up debt, or even commit fraud. 
                             While it can be a scary thought, there are steps you can take to protect yourself.
                             </p>
                    </div>
                    </div>

                    <div class="d-md-flex post-entry-2 half">
                    <Link to="/career-advice/level-up-your-career-hacking-your-way-to-dream-tech-job" class="me-4 thumbnail">
                        <img src="assets/img/dream.jpg" alt="" class="img-fluid" />
                    </Link>
                    <div>
                        <div class="post-meta"><span class="date">Tech</span> <span class="mx-1">&bullet;</span> <span>May 18th '24</span></div>
                        <h3><Link to="/career-advice/level-up-your-career-hacking-your-way-to-dream-tech-job">Level Up Your Career: Hacking Your Way to Your Dream Tech Job</Link></h3>
                        <p className="lead">From mind-blowing gadgets to world-changing apps, tech is everywhere, and it's not going anywhere. 
                      Incase you are feeling a little stuck in your current career path, the daily grind just isn't sparking joy, 
                      and that paycheck isn't quite fueling your dreams. But hey, there's good news! </p>
                    </div>
                    </div>

                    <div class="d-md-flex post-entry-2 half">
                        <Link to="/boost-your-business-visibility-seo-hacks-to-become-the-go-to-business" class="me-4 thumbnail">
                            <img src="assets/img/seo.jpg" alt="" class="img-fluid" />
                        </Link>
                        <div>
                            <div class="post-meta"><span class="date">Tech</span> <span class="mx-1">& Business;</span> <span>May 17th '24</span></div>
                            <h3><Link to="/boost-your-business-visibility-seo-hacks-to-become-the-go-to-business">Boost your Business visibility: SEO Hacks to Become the Go-To Business</Link></h3>
                            <p className="lead">Ever feel like your business is buried in the online jungle? You create amazing products, 
                                provide stellar service, but customers just can't seem to find you? Don't worry, it's not you, it's  Search Engine Optimization (SEO).
                                SEO is the secret sauce that helps search engines like Google recognize your awesomeness 
                                and present you to the right people at the right time
                            </p>
                        </div>
                    </div>

                    <div class="d-md-flex post-entry-2 half">
                        <Link to="#" class="me-4 thumbnail">
                            <img src="assets/img/customer.jpg" alt="" class="img-fluid" />
                        </Link>
                        <div>
                            <div class="post-meta"><span class="date">Tech</span> <span class="mx-1">& Business;</span> <span>May 15th '24</span></div>
                            <h3><Link to="#">Stop Losing Customers! Unleash the Potential of AI Chatbots and Keep Customers Coming Back</Link></h3>
                            <p className="lead">The way businesses interact with customers is undergoing a revolutionary shift. AI-powered chatbots are no longer science fiction; they're a powerful tool that can  transform your customer experience, streamline operations, and boost your bottom line.</p>
                        </div>
                    </div>

                    <div class="d-md-flex post-entry-2 half">
                        <Link to="/ios-18-new-features-will-makes-iphone-more-personal" class="me-4 thumbnail">
                            <img src="assets/img/apple/apple.jpg" alt="" class="img-fluid" />
                        </Link>
                        <div>
                            <div class="post-meta"><span class="date">Tech</span> <span class="mx-1">& Business;</span> <span>JUN 11th '24</span></div>
                            <h3><Link to="/ios-18-new-features-will-makes-iphone-more-personal">Discover the biggest ever redesign! Powerful features in IOS 18 </Link></h3>
                            <p className="lead">Explore all-new options to personalize your phone or tablet. Leverage the power of Apple Intelligence, your personal on-device intelligence system.</p>
                        </div>
                    </div>

                    {/* <div class="text-start py-4">
                        <div class="custom-pagination">
                            <Link to="#" class="prev">Prevous</Link>
                            <Link to="#" class="active">1</Link>
                            <Link to="#">2</Link>
                            <Link to="#">3</Link>
                            <Link to="#">4</Link>
                            <Link to="#">5</Link>
                            <Link to="#" class="next">Next</Link>
                        </div>
                    </div> */}
                </div>

                <SideBar />

                </div>
            </div>
            </section>
    </main>
    <Footer />
  </>
    )
};

export default Technology;