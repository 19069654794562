import React from 'react';
import { Link } from "react-router-dom";


export const Footer = () => (
    <footer id="footer" className="footer">

        <div className="footer-content">
        <div className="container">

            <div className="row g-5">
            <div className="col-lg-4">
                <h3 className="footer-heading">About RayJadore</h3>
                <p>Looking to thrive in business, stay healthy, advance in the world of technology, or simply live a more fulfilling life?
                     We empower you with actionable tips across Business, Health, Relationships, Beauty, Travel, and Well-being.
                      Dive in and unlock your potential!</p>
                <p><Link to="#" className="footer-link-more">Learn More</Link></p>
            </div>
            <div className="col-6 col-lg-2">
                <h3 className="footer-heading">Navigation</h3>
                <ul className="footer-links list-unstyled">
                <li><Link to="/"><i className="bi bi-chevron-right"></i> Home</Link></li>
                <li><Link to="/business"><i className="bi bi-chevron-right"></i> Business</Link></li>
                <li><Link to="/technology"><i className="bi bi-chevron-right"></i> Technology</Link></li>
                <li><Link to="/health"><i className="bi bi-chevron-right"></i> Health</Link></li>
                <li><Link to="#"><i className="bi bi-chevron-right"></i> About us</Link></li>
                <li><Link to="/contact"><i className="bi bi-chevron-right"></i> Contact</Link></li>
                </ul>
            </div>
            {/* <div className="col-6 col-lg-2">
                <h3 className="footer-heading">Categories</h3>
                <ul className="footer-links list-unstyled">
                <li><Link to="category.html"><i className="bi bi-chevron-right"></i> Business</Link></li>
                <li><Link to="category.html"><i className="bi bi-chevron-right"></i> Culture</Link></li>
                <li><Link to="category.html"><i className="bi bi-chevron-right"></i> Sport</Link></li>
                <li><Link to="category.html"><i className="bi bi-chevron-right"></i> Food</Link></li>
                <li><Link to="category.html"><i className="bi bi-chevron-right"></i> Politics</Link></li>
                <li><Link to="category.html"><i className="bi bi-chevron-right"></i> Celebrity</Link></li>
                <li><Link to="category.html"><i className="bi bi-chevron-right"></i> Startups</Link></li>
                <li><Link to="category.html"><i className="bi bi-chevron-right"></i> Travel</Link></li>

                </ul>
            </div> */}

            <div className="col-lg-4">
                <h3 className="footer-heading">Recent Posts</h3>

                <ul className="footer-links footer-blog-entry list-unstyled">
                <li>
                    <Link to="#" className="d-flex align-items-center">
                    <img src="assets/img/post-landscape-8.jpg" alt="" className="img-fluid me-3" />
                    <div>
                        <div className="post-meta d-block"><span className="date">Tech</span> <span className="mx-1">& Business;</span> <span>APR 20th '24</span></div>
                        <span>From Idea to Empire: How to Build a Thriving Startup as a Woman</span>
                    </div>
                    </Link>
                </li>

                <li>
                    <Link to="/how-to-start-your-drop-shipping-business" className="d-flex align-items-center">
                    <img src="assets/img/dropship.jpg" alt="" className="img-fluid me-3" />
                    <div>
                        <div className="post-meta d-block"><span className="date">Business</span> <span className="mx-1">& Tech;</span> <span>May 23th '24</span></div>
                        <span>Be Your Own Boss: How to Launch Your Own Drop Shipping Business</span>
                    </div>
                    </Link>
                </li>

                <li>
                    <Link to="/guide-to-fund-your-business-through-crowdfunding" className="d-flex align-items-center">
                    <img src="assets/img/crowdfunding.jpg" alt="" className="img-fluid me-3" />
                    <div>
                        <div className="post-meta d-block"><span className="date">Business</span> <span className="mx-1">& Tech;</span> <span>Jun 5th '24</span></div>
                        <span>From Idea to Empire: The Ultimate Guide to funding your business through crowdfunding</span>
                    </div>
                    </Link>
                </li>

                <li>
                    <Link to="#" className="d-flex align-items-center">
                    <img src="assets/img/seo2.jpg" alt="" className="img-fluid me-3" />
                    <div>
                        <div className="post-meta d-block"><span className="date">Business</span> <span className="mx-1">& SEO;</span> <span>Jul 5th '22</span></div>
                        <span>Boost your Business visibility: SEO Hacks to Become the Go-To Business</span>
                    </div>
                    </Link>
                </li>

                </ul>

            </div>
            </div>
        </div>
        </div>

        <div className="footer-legal">
        <div className="container">

            <div className="row justify-content-between">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                <div className="copyright">
                ©  <strong><span>RayJadore</span></strong>. All Rights Reserved
                </div>

           

            </div>

            <div className="col-md-6">
                <div className="social-links mb-3 mb-lg-0 text-center text-md-end">
                <a href="https://x.com/ray_jadore" target='_blank' className="twitter"><i className="bi bi-twitter"></i></a>
                <Link to="#" className="facebook"><i className="bi bi-facebook"></i></Link>
                <Link to="#" className="instagram"><i className="bi bi-instagram"></i></Link>
                <Link to="#" className="google-plus"><i className="bi bi-skype"></i></Link>
                <Link to="#" className="linkedin"><i className="bi bi-linkedin"></i></Link>
                </div>

            </div>

            </div>

        </div>
        </div>

  </footer>
);
