import React from "react";
import { Link } from "react-router-dom";

export const Tech = () => {
    return (
        <section className="category-section section">
        <div className="container" data-aos="fade-up">
  
          <div className="section-header d-flex justify-content-between align-items-center mb-5">
            <h2>Technology</h2>
            <div><Link to="/technology" className="more">See All Technology</Link></div>
          </div>
  
          <div className="row">
            <div className="col-md-9">
  
              <div className="d-lg-flex post-entry-2">
                <Link to="/ios-18-new-features-will-makes-iphone-more-personal" className="me-4 thumbnail mb-4 mb-lg-0 d-inline-block">
                  <img src="assets/img/apple/apple.jpg" alt="" className="img-fluid" />
                </Link>
                <div>
                  <div className="post-meta"><span className="date">Tech</span> <span className="mx-1">&Business;</span> <span>Jun 11th '24</span></div>
                  <h3><Link to="/ios-18-new-features-will-makes-iphone-more-personal">Discover the biggest ever redesign! Powerful features in IOS 18 </Link></h3>
                  <p>Apple has announced the latest iteration of its mobile operating system, iOS 18, which promises to revolutionize the way users interact with 
                    their devices. The update brings a plethora of new features, including enhanced customization options, a major redesign of the Photos app,
                     and innovative ways to manage email and messaging</p>
                  <div className="d-flex align-items-center author">
                    <div className="photo"><img src="assets/img/person-2.jpg" alt="" className="img-fluid" /></div>
                    <div className="name">
                    </div>
                  </div>
                </div>
              </div>
  
              <div className="row">
                <div className="col-lg-4">
                  <div className="post-entry-1 border-bottom">
                    <Link to="/career-advice/level-up-your-career-hacking-your-way-to-dream-tech-job"><img src="assets/img/hacking.jpg" alt="" className="img-fluid" /></Link>
                    <div className="post-meta"><span className="date">Tech</span> <span className="mx-1"></span> <span>Jun 26th '24</span></div>
                    <h2 className="mb-2"><Link to="/career-advice/level-up-your-career-hacking-your-way-to-dream-tech-job">Level Up Your Career: Hacking Your Way to Your Dream Tech Job</Link></h2>
                    <p className="mb-4 d-block">From mind-blowing gadgets to world-changing apps, tech is everywhere, and it's not going anywhere. 
                      Incase you are feeling a little stuck in your current career path, the daily grind just isn't sparking joy, 
                      and that paycheck isn't quite fueling your dreams. But hey, there's good news! 
                     </p>
                  </div>
  
                  <div className="post-entry-1">
                    <div className="post-meta"><span className="date">Tech</span> <span className="mx-1"></span> <span>Jun 5th '22</span></div>
                    <h2 className="mb-2"><Link to="#">5 Great Startup Tips for Female Founders</Link></h2>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="post-entry-1">
                    <Link to="/boost-your-business-visibility-seo-hacks-to-become-the-go-to-business"><img src="assets/img/seo2.jpg" alt="" className="img-fluid" /></Link>
                    <div className="post-meta"><span className="date">Tech</span> <span className="mx-1">&business;</span> <span>Jun 5th '24</span></div>
                    <h2 className="mb-2"><Link to="/boost-your-business-visibility-seo-hacks-to-become-the-go-to-business">Boost your Business visibility: SEO Hacks to Become the Go-To Business</Link></h2>
                    <p className="mb-4 d-block">Ever feel like your business is buried in the online jungle? You create amazing products, provide stellar service,
                       but customers just can't seem to find you? Don't worry, it's not you, it's Search Engine Optimization (SEO).
                        SEO is the secret sauce that helps search engines like Google recognize your awesomeness and present you to
                         the right people at the right time</p>
                  </div>
                </div>
              </div>
            </div>
  
            <div className="col-md-3">
              <div className="post-entry-1 border-bottom">
                <div className="post-meta"><span className="date">Tech</span> <span className="mx-1"></span> <span>Jun 27th '24</span></div>
                <h2 className="mb-2"><Link to="/keyword-research-for-on-page-seo-the-secret-weapon-of-business-growth">Keyword Research for On-Page SEO: The Secret Weapon of Business Growth</Link></h2>
               
              </div>
  
              <div className="post-entry-1 border-bottom">
                <div className="post-meta"><span className="date">Tech</span> <span className="mx-1"></span> <span>May 19th '24</span></div>
                <h2 className="mb-2"><Link to="#">From Idea to Empire: The Ultimate Guide to funding your business through crowdfunding</Link></h2>
              
              </div>
  
              <div className="post-entry-1 border-bottom">
                <div className="post-meta"><span className="date">Tech</span> <span className="mx-1"></span> <span>Jun 9th '24</span></div>
                <h2 className="mb-2"><Link to="#">From Idea to Empire: How to Build a Thriving Startup as a Woman</Link></h2>
               
              </div>
  
              <div className="post-entry-1 border-bottom">
                <div className="post-meta"><span className="date">Tech</span> <span className="mx-1"></span> <span>Jul 8th '24</span></div>
                <h2 className="mb-2"><Link to="#">Shielding Yourself: How to Prevent Identity Theft</Link></h2>
              
              </div>
  
              <div className="post-entry-1 border-bottom">
                <div className="post-meta"><span className="date">Tech</span> <span className="mx-1"></span> <span>Jun 11th '24</span></div>
                <h2 className="mb-2"><Link to="#">The Best Homemade Masks for Face (keep the Pimples Away)</Link></h2>
                
              </div>
  
              <div className="post-entry-1 border-bottom">
                <div className="post-meta"><span className="date">Tech</span> <span className="mx-1"></span> <span>Jun 13th '24</span></div>
                <h2 className="mb-2"><Link to="#">10 Life-Changing Hacks Every Working Mom Should Know</Link></h2>
               
              </div>
            </div>
          </div>
        </div>
      </section>
    )
};
