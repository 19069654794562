import React from "react";
import { BrowserRouter as Router, Routes, Route,  createBrowserRouter, RouterProvider } from 'react-router-dom';

import Home from './screens/home';
import Technology from './screens/category/tech';
import Health from './screens/category/health';
import Business from './screens/category/business';
import Contact from './screens/contact';
import {
  ExportBusiness,
  Dropshipping,
  OnlineArbitrage,
  Grants,
  CrowdFunding,
} from './screens/article/business';

import { 
  Fertility,
  IVF,
  Outbreak,
 } from "./screens/article/health";

 import {
  Iphone18, 
  Visibility,
  TechDream,
  KeywordResearch
} from './screens/article/tech'

 

function App() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<Home />} />
    
      <Route path="/technology" element={<Technology />} />
      <Route path="/career-advice/level-up-your-career-hacking-your-way-to-dream-tech-job" element={<TechDream />} />
      <Route path="/keyword-research-for-on-page-seo-the-secret-weapon-of-business-growth" element={<KeywordResearch />} />
      <Route path="/ios-18-new-features-will-makes-iphone-more-personal" element={<Iphone18 />} />
      <Route path="/boost-your-business-visibility-seo-hacks-to-become-the-go-to-business" element={<Visibility />} />

      <Route path="/health" element={<Health />} />
      <Route path="/building-your-dream-family-with-ivf" element={<IVF />} />
      <Route path="/optimize-cycle-pregnancy-performance" element={<Fertility />} />
      <Route path="/cholera-cases-rise-n-nigeria-as-rainy-season-begins" element={<Outbreak />} />

      <Route path="/business" element={<Business />} />
      <Route path="/guide-to-fund-your-business-through-crowdfunding" element={<CrowdFunding />} />
      <Route path="/how-to-start-your-lucrative-import-export-business" element={<ExportBusiness />} />
      <Route path="/how-to-start-your-drop-shipping-business" element={<Dropshipping />} />
      <Route path="/online-arbitrage-guide/amazon-seller-tips" element={<OnlineArbitrage />} />
      <Route path="/female-founder-startup-grants" element={<Grants />} />


      <Route path="/contact" element={<Contact />} />
    </Routes>
  </Router>
  );
}

export default App;
