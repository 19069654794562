import React from "react";
import { Helmet } from "react-helmet";
import { Header, Footer, SideBar } from '../../../custom';


export const Outbreak = ({}) => {
    React.useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }, []);
    
    return (
        <>
            <Helmet>
                <title lang="en">Cholera outbreak in Nigeria 2024</title> 
                <meta name="description" content="cholera outbreak, nigeria cholera toll increases" /> 
                <meta property="og:title" content="Cholera Cases Rise in Nigeria as Rainy Season Begins"/>
                <meta name="keywords" content=" " />
                {/* <link rel="canonical" href="https://rayjadore.com/how-to-start-your-drop-shipping-business"></link> */}
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:description" content="Cholera Cases Rise in Nigeria as Rainy Season Begins, outbreak of cholera" />
                {/* <meta property="og:url" content="https://rayjadore.com/business" /> */}
                <meta property="og:site_name" content="RayJadore" /> 
            </Helmet>
            <Header />
            <main id="main" className="lead">
                <section className="single-post-content section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 post-content" data-aos="fade-up">

                                <div className="single-post">
                                <div className="post-meta"><span className="date">Business</span> <span className="mx-1">&bullet;</span> <span>Jun 16th '24</span></div>
                                <h1 className="mb-5">Cholera Cases Rise in Nigeria as Rainy Season Begins</h1>
                                <p><span className="firstcharacter">N</span>igeria's health officials are warning of a growing number of cholera cases across the 
                                country. Since the beginning of the year, there have been over 1,141 suspected cases and 65 confirmed cases with 30 deaths reported 
                                in 96 local governments across 30 states. This increase coincides with the start of the rainy season, which can create ideal 
                                conditions for the spread of cholera. <br/>  <br/>

                                <img src="assets/img/cholera.jpeg" alt="" class="img-fluid" />

                                <br/>  <br/>

                                Lagos State has been particularly affected, with a recent outbreak resulting in 60 hospitalizations and five deaths within just two 
                                days. The Nigeria Centre for Disease Control and Prevention (NCDC) issued a public health advisory to raise awareness and urge 
                                Nigerians to take precautions. <br/>
                                While the total number of confirmed cases is 65, the suspected cases are much higher at 1,141. <br/>
                                Ten states – Bayelsa, Zamfara, Abia, Cross River, Bauchi, Delta, Katsina, Imo, Nasarawa, and Lagos – account for 90% of these
                                 suspected cases.
                                </p>
                                <h2>What is cholera?</h2>
                                <p>
                                Cholera is a contagious disease that spreads through contaminated food or water. You can catch it directly by ingesting the bacteria,
                                 or indirectly from poor hygiene practices in your environment. Symptoms often come on suddenly and include severe watery diarrhea,
                                  vomiting, and possibly fever. While some people may have no symptoms at all, others can become dehydrated very quickly,
                                   which can be life-threatening in severe cases. <br/>

                                Imagine your stomach is like a calm lake. Cholera is like a nasty bug that throws rocks into that lake, causing major disruptions. Here's what happens:
                                <ul className="list-group">
                                <li className="list-group-item"><h5>The Bug Strikes:</h5> You swallow the cholera bacteria, often through contaminated water or food.</li>
                                <li className="list-group-item"><h5>The Lake Gets Rough:</h5> The bacteria irritates your gut, causing it to release a lot of fluids. This is like a storm churning up the lake water.</li>
                                <li className="list-group-item"><h5>Dehydration Sets In:</h5> All that lost fluid leads to dehydration, which can be dangerous. It's like the lake drying up, leaving you weak and dizzy.</li>
                                </ul>
                                <h3 className="mt-5">Symptoms to Watch Out For</h3>
                                <ul className="list-group">
                                    <li className="list-group-item">Severe diarrhea (frequent watery stools)</li>                     
                                    <li className="list-group-item">Vomiting</li>
                                    <li className="list-group-item">Dehydration (feeling very thirsty, dry mouth, less frequent urination)</li>
                                    <li className="list-group-item">Stomach cramps</li>
                                </ul>

                                <h3 className="mt-5">How to Avoid Getting Cholera</h3>
                                <ul className="list-group">
                                    <li className="list-group-item"><h5>Drink clean, treated water:</h5> Bottled water or properly boiled/filtered water is best.</li>                     
                                    <li className="list-group-item"><h5>Wash your hands often:</h5> Especially before eating and after using the bathroom.</li>
                                    <li className="list-group-item"><h5>Be careful with raw food:</h5> Fruits and vegetables should be washed thoroughly or peeled. stay away from unverified buk</li>
                                    <li className="list-group-item"><h5>Cook food thoroughly:</h5> Especially seafood and meats.</li>
                                </ul>
                                <br/>
                                    Cholera is treatable if caught early. If you experience any of the symptoms, seek medical attention immediately.
                                </p>
                                <h2 className="mt-5">Simple Steps to Protect Yourself from Cholera</h2>
                                <p>
                                    The Nigerian Centre for Disease Control and Prevention (NCDC) offers clear steps to help you avoid getting cholera:
                                    <ul className="list-group">
                                        <li className="list-group-item">Drink Safe Water: Make sure your drinking water is boiled and stored hygienically in a covered container.</li>
                                        <li className="list-group-item">Practice Good Hygiene: Wash your hands frequently with soap and clean running water. 
                                            If soap and water aren't available, use an alcohol-based hand sanitizer.</li>
                                        <li className="list-group-item">Cook Food Thoroughly: Don't take any chances with undercooked food. 
                                            Make sure everything is steaming hot before eating.</li>
                                        <li className="list-group-item">Wash Fruits and Vegetables: Wash all raw produce thoroughly with safe water before consuming it.</li>
                                        <li className="list-group-item">Avoid Risky Foods: Steer clear of raw or undercooked seafood, food from street vendors, 
                                            and unwashed fruits and vegetables.</li>
                                        <li className="list-group-item">Maintain Cleanliness: Proper waste disposal is key. Avoid open defecation and littering.
                                             Regularly clear sewage to prevent contamination.</li>
                                        <li className="list-group-item">Seek Medical Attention Immediately: If you experience sudden watery diarrhea, don't try to 
                                            treat it yourself. Visit a healthcare facility right away.</li>
                                    </ul>
                                </p>

                                 </div>
                            </div>

                            <SideBar />
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
       
    )
};
